import React from "react";
// import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image";

import Layout from "../components/layout";
import Seo from "../components/seo";

const PaartherapiePage = () => (
  <Layout>
    <Seo
      title="Paartherapie | Verhaltenstherapie München | Praxis für Psychotherapie"
      description="Psychologische Beratung & Coaching in Phasen von Veränderung, lebensrelevanten Fragestellungen, Stressmanagement & Entwicklung der Persönlichkeit."
    />

    <div className="relative">
      <div className="w-full h-96">
        <StaticImage
          className="w-full h-full"
          src="../assets/images/hero-ueber-mich.jpg"
          alt=""
        />
        <div className="absolute top-0 flex flex-wrap items-center w-full h-full place-items-center">
          <div className="w-full text-center text-white">
            <h1 className="lg:text-4xl">Paartherapie</h1>
            {/* <div>Diese Seite befindet sich im derzeit im Aufbau. Bei Fragen wenden Sie sich gerne an mich</div> */}
          </div>
        </div>
      </div>
    </div>

    <div className="my-12 boxed max-w-prose generic-page">
      <p>
        Eine Partnerschaft zu führen, bedeutet immer auch Engagement und Arbeit.
        Nur allzu schnell passiert es im Alltag, dass wir einander aus den Augen
        verlieren, ständig wegen gleicher Themen streiten und uns voneinander
        entfernen, einsam und unverstanden fühlen. Wie verflogen erscheinen
        Leichtigkeit, Wärme und Leidenschaft vom Anfang. Stattdessen erleben
        Paare in solchen Zeiten Spannung, Streitigkeiten oder auch eisige
        Stille.
      </p>
      <p>
        Das kann beispielsweise nach der Geburt eines Kindes passieren, nach
        Auszug der Kinder, nach der Eheschließung, nach Zusammenzug, nach einer
        Außenbeziehung („Seitensprung“) oder langjähriger Beziehung.
      </p>
      <p>
        Dabei ist es meist kein Mangel an Liebe, der zu den Problemen führt,
        sondern dysfunktionale Interaktionsmuster. Das bedeutet, dass es im
        Rahmen der Beziehung zur Entstehung von Teufelskreisen in der
        Kommunikation kommt, die sich rasant aufschaukeln können, sodass
        manchmal schon ein Blick genügt, um eine tiefe Verletzung entstehen zu
        lassen.
      </p>
      <p>
        Im Rahmen der Paartherapie geht es darum, sich diesen Mustern zuzuwenden
        und diese zu verändern. Die Problematik findet sich nicht in einem
        Menschen, sondern in der Beziehung. Beide Partner tragen einen Anteil
        dazu bei und auch beide haben die Fähigkeit, die Situation zu
        verbessern. Es geht nicht um Schuldzuweisungen, sondern um ein
        Wiederentdecken von Mitgefühl, Interesse, Humor und Anziehung.
      </p>
      <p>
        Beide Seiten werden wertungsfrei gehört, denn ein respektvoller Umgang
        mit Ihnen ist mir sehr wichtig und auch essentiell, damit jede und jeder
        sich sicher und verstanden fühlen kann, um ihre und seine Bedürfnisse zu
        äußern.
      </p>
      <p>
        Die Sitzungsanzahl wird individuell vereinbart. Für die Paartherapie
        empfiehlt es sich meist, mindestens zwei Wochen bis zur nächsten Sitzung
        vergehen zu lassen, denn neue Strategien brauchen Zeit, um im Alltag
        miteinander ausprobiert zu werden.
      </p>
    </div>
  </Layout>
);

export default PaartherapiePage;
